// api.js
import API from "@/api"

const resource = '/admin/settings';

export default {
    getAdminSettings() {
        return API.get(`${resource}`);
    },
    createAdminSetting(key, value, setting_metadata) {
        return API.post(`${resource}`, { key, value, setting_metadata });
    },
    updateAdminSetting(key, value, setting_metadata) {
        return API.put(`${resource}/${key}`, { value, setting_metadata });
    },
    deleteAdminSetting(key) {
        return API.delete(`${resource}/${key}`);
    },
    sendInvite(email, name) {
        return API.post(`${resource}/invites`, { email, name });
    },
    getInvitedUsers() {
        return API.get(`${resource}/invites`);
    },
};
