<template>
  <v-container fluid class="admin-settings">
    <v-row>
      <v-col cols="12">
        <h1 class="text-h4 mb-6">Admin Dashboard</h1>
      </v-col>
    </v-row>

    <v-tabs v-model="tab">
      <v-tab href="#settings">Settings</v-tab>
      <v-tab href="#user-invites">User Invites</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item value="settings">
        <v-row>
          <v-col cols="12">
            <v-card elevation="2" class="mb-6">
              <v-card-title class="headline">
                Settings
                <v-spacer></v-spacer>
                <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                class="mr-4"
              ></v-text-field>
                <v-btn color="primary" dark @click="dialog = true">
                  <v-icon left>mdi-plus</v-icon>
                  New Setting
                </v-btn>
              </v-card-title>
              <v-data-table
                :headers="headers"
                :items="filteredSettings"
                :items-per-page="25"
                :search="search"
                class="elevation-1"
              >
                <template v-slot:item.value="{ item }">
                  <span v-if="item.setting_metadata.type !== 'array'">{{ item.value }}</span>
                  <span v-else>{{ formatArrayValue(item.value) }}</span>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
                  <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
        <!-- Edit/Create Dialog -->
        <v-dialog v-model="dialog" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-form ref="form" v-model="formValid">
                  <v-container>
                  <v-row>
                      <v-col cols="12">
                      <v-text-field
                      v-model="editedItem.key"
                      label="Key"
                      :disabled="editedIndex > -1"
                      :rules="editedIndex > -1 ? [rules.required] : [rules.required, rules.keyUnique]"
                      @input="validateForm"
                      ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                      <v-select
                          v-model="editedItem.setting_metadata.type"
                          :items="['string', 'number', 'boolean', 'array']"
                          label="Type"
                          @change="updateValueField"
                      ></v-select>
                      </v-col>
                      <v-col cols="12">
                      <v-textarea
                          v-if="editedItem.setting_metadata.type === 'array'"
                          v-model="editedItem.value"
                          label="Value (one item per line)"
                          hint="Enter one email or domain per line"
                          persistent-hint
                      ></v-textarea>
                      <v-text-field
                          v-else-if="editedItem.setting_metadata.type === 'string'"
                          v-model="editedItem.value"
                          label="Value"
                      ></v-text-field>
                      <v-text-field
                          v-else-if="editedItem.setting_metadata.type === 'number'"
                          v-model.number="editedItem.value"
                          label="Value"
                          type="number"
                      ></v-text-field>
                      <v-switch
                          v-else-if="editedItem.setting_metadata.type === 'boolean'"
                          v-model="editedItem.value"
                          label="Value"
                      ></v-switch>
                      </v-col>
                      <v-col cols="12">
                      <v-text-field v-model="editedItem.setting_metadata.description" label="Description"></v-text-field>
                      </v-col>
                  </v-row>
                  </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="save" :disabled="!formValid">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Delete Confirmation Dialog -->
        <v-dialog v-model="confirmDialog" max-width="400px">
          <v-card>
            <v-card-title class="text-h5">Confirm Deletion</v-card-title>
            <v-card-text>Are you sure you want to delete this setting?</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="grey darken-1" text @click="confirmDialog = false">Cancel</v-btn>
              <v-btn color="red darken-1" text @click="confirmDelete">Confirm</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-tab-item>
      <v-tab-item value="user-invites">
        <UserInvites />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import AdminSettingsApi from '@/admin_settings/api';
import UserInvites from './UserInvites.vue';

export default {
  name: 'AdminSettings',
  components: {
    UserInvites,
  },
  data() {
    return {
      tab: null,
      formValid: true,
      search: '',
      rules: {
          required: value => !!value || 'Required.',
          keyUnique: value => {
              if (this.editedIndex > -1) return true; // Skip this validation for editing
              if (!value) return true; // Let the required rule handle empty values
              const exists = this.settings.some(setting => setting.key === value);
              return !exists || 'Key must be unique.';
          }
          },
      dialog: false,
      confirmDialog: false,
      itemtoDelete: null,
      settings: [],
      headers: [
        { text: 'Key', value: 'key' },
        { text: 'Value', value: 'value' },
        { text: 'Description', value: 'setting_metadata.description' },
        { text: 'Type', value: 'setting_metadata.type' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      editedIndex: -1,
      editedItem: {
        key: '',
        value: '',
        setting_metadata: {
          description: '',
          type: 'string',
        },
      },
      defaultItem: {
        key: '',
        value: '',
        setting_metadata: {
          description: '',
          type: 'string',
        },
      },
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Setting' : 'Edit Setting';
    },
    filteredSettings() {
    if (!this.search) return this.settings;

    return this.settings.filter(setting => {
      const searchTerm = this.search.toLowerCase();
      return (
        setting.key.toLowerCase().includes(searchTerm) ||
        (typeof setting.value === 'string' && setting.value.toLowerCase().includes(searchTerm)) ||
        setting.setting_metadata.description.toLowerCase().includes(searchTerm) ||
        setting.setting_metadata.type.toLowerCase().includes(searchTerm)
      );
    });
  },
  },
  mounted() {
    this.fetchSettings();
  },
  methods: {
      validateForm() {
          if (this.editedIndex > -1) {
          // For editing, consider the form valid if all visible fields are valid
          this.formValid = this.$refs.form.validate();
          } else {
          // For new items, validate all fields including the key
          this.formValid = this.$refs.form.validate() && this.rules.keyUnique(this.editedItem.key) === true;
          }
      },
      initializeEditedItem() {
          this.editedItem = {
              key: '',
              value: '',
              setting_metadata: {
              type: 'string',
              description: ''
              }
          };
          },
      updateValueField() {
      switch (this.editedItem.setting_metadata.type) {
          case 'boolean':
          this.editedItem.value = false;
          break;
          case 'number':
          this.editedItem.value = 0;
          break;
          case 'array':
          this.editedItem.value = '';
          break;
          default:
          this.editedItem.value = '';
      }
      },
      formatArrayValue(value) {
      if (Array.isArray(value)) {
          return value.slice(0, 3).join(', ') + (value.length > 3 ? `, ... (+${value.length - 3} more)` : '');
      }
      return value;
      },
      editItem(item) {
          this.editedIndex = this.settings.indexOf(item);
          this.editedItem = JSON.parse(JSON.stringify(item));
          if (this.editedItem.setting_metadata.type === 'array' && Array.isArray(this.editedItem.value)) {
              this.editedItem.value = this.editedItem.value.join('\n');
          }
          this.dialog = true;
          this.$nextTick(() => {
              if (this.$refs.form) {
              this.$refs.form.resetValidation();
              }
              this.validateForm(); // Validate after resetting to ensure correct state
          });
      },
    async fetchSettings() {
      try {
        const response = await AdminSettingsApi.getAdminSettings();
        this.settings = response.data;
      } catch (error) {
        console.error('Error fetching settings:', error);
        alert('Failed to fetch settings');
      }
    },
    async deleteItem(item) {
      this.itemToDelete = item;
      this.confirmDialog = true;
    },
    async confirmDelete() {
      try {
          await AdminSettingsApi.deleteAdminSetting(this.itemToDelete.key);
          this.settings = this.settings.filter(setting => setting.key !== this.itemToDelete.key);
          alert('Setting deleted successfully');
      } catch (error) {
          console.error('Error deleting setting:', error);
          alert('Failed to delete setting');
      }
      this.confirmDialog = false;
      this.itemToDelete = null;
      },
      close() {
          this.dialog = false;
          this.$nextTick(() => {
              this.editedItem = Object.assign({}, this.defaultItem);
              this.editedIndex = -1;
              if (this.$refs.form) {
              this.$refs.form.resetValidation();
              }
              this.formValid = false; // Reset form validity
          });
          },
          async save() {
            this.validateForm();
            if (!this.formValid) {
              alert('Please correct the errors before submitting.');
              return;
            }

            try {
              // Clone the editedItem to avoid modifying the original
              const itemToSave = JSON.parse(JSON.stringify(this.editedItem));

              // Convert the value based on the type
              if (itemToSave.setting_metadata.type === 'array') {
                // Split the textarea input into an array and remove empty lines
                itemToSave.value = itemToSave.value.split('\n').filter(item => item.trim() !== '');
              } else if (itemToSave.setting_metadata.type === 'number') {
                // Ensure the value is a number
                itemToSave.value = Number(itemToSave.value);
              } else if (itemToSave.setting_metadata.type === 'boolean') {
                // Ensure the value is a boolean
                itemToSave.value = Boolean(itemToSave.value);
              }

              if (this.editedIndex > -1) {
                // Updating existing item
                await AdminSettingsApi.updateAdminSetting(itemToSave.key, itemToSave.value, itemToSave.setting_metadata);
                Object.assign(this.settings[this.editedIndex], itemToSave);
              } else {
                // Creating new item
                const response = await AdminSettingsApi.createAdminSetting(itemToSave.key, itemToSave.value, itemToSave.setting_metadata);
                this.settings.push(response.data);
              }
              this.close();
              alert(`Setting ${this.editedIndex > -1 ? 'updated' : 'created'} successfully`);
            } catch (error) {
              console.error('Error saving setting:', error);
              alert(`Failed to ${this.editedIndex > -1 ? 'update' : 'create'} setting`);
            }
          },
  },
};
</script>


<style scoped>
.admin-settings {
  padding-top: 2rem;
}

.v-btn {
  text-transform: none;
}
</style>
