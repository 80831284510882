<!-- UserInvites.vue -->
<template>
    <v-card class="mt-4">
      <v-card-title>
        User Invites
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <v-card-text>
        <v-form @submit.prevent="sendInvite" ref="inviteForm">
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="newInvite.email"
                label="Email"
                :rules="[rules.required, rules.email]"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="newInvite.name"
                label="Name"
                :rules="[rules.required]"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-btn type="submit" color="primary" :loading="loading">Send Invite</v-btn>
        </v-form>
      </v-card-text>

      <v-data-table
        :headers="headers"
        :items="invitedUsers"
        :search="search"
        :loading="loading"
      >
      <template v-slot:item.accepted="{ item }">
        <v-chip :color="getStatusColor(item.accepted)">
          {{ getStatusText(item.accepted) }}
        </v-chip>
      </template>
      <template v-slot:item.created_at="{ item }">
        {{  item.created_at | formatRelativeDate }}
      </template>
      </v-data-table>
    </v-card>
</template>

<script>
  import AdminSettingsApi from '@/admin_settings/api';

  export default {
    name: 'UserInvites',
    data() {
      return {
        search: '',
        loading: false,
        newInvite: {
          email: '',
          name: '',
        },
        invitedUsers: [],
        headers: [
          { text: 'Name', value: 'name' },
          { text: 'Email', value: 'email' },
          { text: 'Status', value: 'accepted' },
          { text: 'Invited At', value: 'created_at' },
        ],
        rules: {
          required: v => !!v || 'This field is required',
          email: v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        },
      };
    },
    methods: {
      async sendInvite() {
        if (!this.$refs.inviteForm.validate()) return;

        this.loading = true;
        const inviteData = { ...this.newInvite }; // Create a copy of newInvite

        try {
          await AdminSettingsApi.sendInvite(inviteData.email, inviteData.name);

          await this.fetchInvitedUsers();
          await this.updateAllowedUsers(inviteData.email);

          this.newInvite = { email: '', name: '' };
          this.$refs.inviteForm.reset();

          alert("Invite sent successfully");
        } catch (error) {
          let errorMessage = "Failed to send invite";
          if (error.response && error.response.data && error.response.data.detail) {
            errorMessage = error.response.data.detail;
          }
          alert(errorMessage);
        } finally {
          this.loading = false;
        }
      },
      async updateAllowedUsers(newEmail) {
        try {
          const response = await AdminSettingsApi.getAdminSettings();
          const settings = response.data;
          const currentSetting = settings.find(setting => setting.key === 'GOOGLE_OIDC_ALLOWED_USERS');

          if (!currentSetting) {
            throw new Error('GOOGLE_OIDC_ALLOWED_USERS setting not found');
          }

          // Ensure currentSetting.value is an array
          let currentEmails = Array.isArray(currentSetting.value) ? currentSetting.value : [];

          // Check if the new email already exists
          if (!currentEmails.includes(newEmail)) {
            // If it doesn't exist, add it to the array
            currentEmails.push(newEmail);

            // Update the setting
            await AdminSettingsApi.updateAdminSetting(
              'GOOGLE_OIDC_ALLOWED_USERS',
              currentEmails,
              currentSetting.setting_metadata
            );
          }
        } catch (error) {
          let errorMessage = "Failed to update allowed users";
          if (error.response && error.response.data && error.response.data.detail) {
            errorMessage = error.response.data.detail;
          }
          alert(errorMessage);
        }
      },
      getStatusColor(accepted) {
        return accepted ? 'green' : 'orange';
      },

      getStatusText(accepted) {
        return accepted ? 'Accepted' : 'Pending';
      },
      async fetchInvitedUsers() {
        this.loading = true;
        try {
          const response = await AdminSettingsApi.getInvitedUsers();
          this.invitedUsers = response.data;
        } catch (error) {
          alert("Failed to fetch invited users");
        } finally {
          this.loading = false;
        }
      },
    },
    mounted() {
      this.fetchInvitedUsers();
    },
  };
</script>
